import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1071.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 6697 l0 -4104 122 -6 c66 -4 171 -7 232 -8 61 0 134 -4 161 -9
l50 -7 -42 -2 -43 -1 0 -50 0 -50 120 0 c73 0 120 4 120 10 0 6 47 10 120 10
73 0 120 -4 120 -10 0 -6 33 -10 80 -10 47 0 80 4 80 10 0 7 287 10 840 10
553 0 840 3 840 10 0 7 153 10 440 10 433 0 440 0 440 -20 0 -19 7 -20 160
-20 104 0 160 4 160 10 0 7 62 11 183 10 100 0 200 0 222 0 l40 1 -55 20 c-30
11 -71 24 -90 30 -48 14 -117 45 -290 128 -58 28 -136 68 -175 90 -38 22 -77
44 -85 47 -15 7 -95 61 -117 79 -30 24 -40 32 -73 55 -50 35 -70 51 -103 83
-16 15 -30 27 -33 27 -8 0 -169 145 -198 179 -12 13 -49 53 -83 88 -34 35 -72
78 -84 94 -12 17 -32 42 -44 56 -43 48 -55 63 -55 69 0 3 -11 20 -25 38 -14
18 -25 38 -25 43 0 6 -7 16 -15 23 -9 7 -29 33 -46 57 -23 36 -27 48 -18 59
10 12 7 14 -12 12 -21 -3 -24 1 -21 25 2 18 -1 26 -7 22 -6 -3 -11 1 -11 9 0
8 4 18 10 21 16 10 12 65 -5 65 -8 0 -15 5 -15 10 0 6 7 10 15 10 13 0 15 29
15 200 0 149 3 200 12 200 9 0 9 3 0 12 -7 7 -12 23 -12 36 0 36 24 12 46 -44
9 -24 21 -44 25 -44 5 0 9 -12 9 -26 0 -14 9 -39 20 -55 12 -15 26 -42 31 -59
6 -16 15 -30 20 -30 5 0 9 -5 9 -11 0 -12 117 -200 175 -282 85 -119 373 -437
397 -437 4 0 18 -10 31 -22 64 -61 199 -168 211 -168 7 0 87 -51 96 -60 14
-16 118 -75 218 -125 114 -57 135 -61 167 -28 9 9 23 12 33 8 13 -5 8 -10 -23
-21 -22 -7 -42 -14 -44 -14 -2 0 2 -6 8 -14 6 -8 26 -16 43 -18 26 -4 28 -3
15 7 -14 10 -1 11 64 8 44 -2 89 -8 100 -14 13 -7 25 -7 37 0 15 9 14 10 -8
11 -15 0 -31 5 -37 11 -6 6 -17 11 -24 13 -8 1 -35 12 -60 23 -26 12 -63 27
-83 34 -20 7 -36 17 -36 21 0 4 -8 8 -19 8 -10 0 -29 8 -42 18 -13 10 -40 25
-59 32 -19 8 -37 16 -40 20 -3 3 -27 17 -53 30 -26 14 -56 32 -65 40 -9 8 -30
22 -46 30 -16 8 -42 27 -58 42 -16 15 -37 29 -46 33 -9 3 -34 21 -54 40 -20
19 -39 35 -42 35 -18 0 -287 268 -322 320 -12 19 -35 49 -51 67 -48 54 -62 72
-89 115 -14 24 -31 48 -38 53 -6 6 -19 28 -30 50 -10 22 -25 52 -33 68 -12 24
-12 28 1 33 8 3 12 12 10 19 -3 7 -6 116 -7 242 -2 252 -5 245 53 120 12 -26
26 -47 31 -47 5 0 9 -8 9 -18 0 -10 25 -60 55 -111 30 -51 55 -95 55 -97 0 -8
133 -189 151 -207 11 -10 19 -21 19 -25 0 -4 16 -24 35 -44 19 -20 35 -41 35
-47 0 -6 7 -11 15 -11 8 0 15 -3 15 -7 0 -10 59 -70 99 -103 17 -14 31 -29 31
-35 0 -6 12 -16 28 -22 15 -7 41 -26 57 -41 17 -16 41 -34 53 -41 18 -10 22
-9 22 4 0 9 7 23 15 31 8 9 15 11 15 5 0 -6 7 -11 15 -11 8 0 15 -4 15 -10 0
-5 -7 -10 -16 -10 -8 0 -12 -5 -8 -11 3 -6 1 -14 -5 -18 -7 -5 -3 -15 14 -31
25 -24 33 -14 15 20 -7 13 -6 19 6 24 9 4 14 13 10 21 -4 13 8 15 79 15 50 0
85 4 85 10 0 6 33 10 80 10 64 0 80 3 80 15 0 11 11 15 40 15 29 0 40 4 40 15
0 11 11 15 40 15 22 0 40 5 40 10 0 6 33 10 80 10 64 0 80 3 80 15 0 13 20 15
120 15 l120 0 0 45 0 45 40 0 40 0 0 43 0 44 82 -3 c53 -2 76 0 63 6 -17 7
-17 9 -2 9 20 1 23 17 5 24 -7 3 -3 6 10 6 22 1 52 22 52 36 0 9 -44 -6 -52
-18 -4 -6 -35 -12 -71 -14 -70 -3 -107 15 -107 55 0 39 9 48 45 44 21 -3 42 1
52 9 12 10 15 10 9 2 -4 -7 -3 -13 3 -13 19 0 21 17 3 32 -9 7 -30 20 -47 28
-61 30 -85 84 -65 145 16 49 60 73 142 77 76 3 89 -4 91 -53 l2 -34 -57 -3
c-70 -4 -76 -21 -17 -47 58 -26 89 -71 89 -126 0 -25 -5 -50 -12 -57 -9 -9 -9
-15 0 -24 9 -9 12 -7 12 11 0 12 4 20 9 17 5 -3 9 61 10 145 1 102 -2 149 -9
144 -5 -3 -10 1 -10 9 0 10 6 13 15 10 10 -4 15 0 15 11 0 10 4 13 12 8 7 -4
50 -6 96 -4 105 3 135 -11 130 -66 l-3 -37 -62 3 c-59 2 -63 1 -63 -20 0 -21
1 -21 18 -6 10 10 23 17 28 17 5 0 2 -5 -6 -10 -12 -8 -10 -10 10 -10 44 -1
55 -15 54 -71 0 -34 2 -46 7 -33 6 15 8 12 8 -13 1 -17 6 -35 11 -38 13 -8 13
-65 0 -85 -7 -11 -35 -17 -102 -21 l-93 -6 95 0 c75 0 97 3 107 16 8 11 12 56
11 141 -2 124 -2 124 -8 40 l-7 -85 1 90 c1 71 4 89 15 87 10 -2 12 4 6 25
-11 39 -8 65 7 74 21 13 200 0 226 -17 21 -14 25 -13 51 6 23 15 39 19 72 15
55 -7 65 -16 73 -67 4 -23 11 -47 16 -53 4 -5 10 -23 12 -40 3 -25 7 -18 22
40 28 101 47 125 99 123 23 0 45 -6 50 -12 5 -7 9 -8 15 -1 4 6 30 10 57 10
l50 0 3 -137 c2 -113 5 -138 17 -138 21 0 29 -44 13 -76 -14 -30 -34 -36 -90
-27 -54 8 -61 34 -56 208 2 80 1 145 -2 145 -6 0 -50 -139 -50 -157 0 -16 -48
-181 -56 -194 -6 -11 -113 -10 -129 1 -5 4 -18 10 -27 13 -14 5 -18 1 -18 -21
0 -20 3 -23 10 -12 13 21 24 5 11 -18 -10 -18 -7 -19 32 -16 23 2 75 1 115 -3
l72 -5 0 -39 c0 -39 0 -39 40 -39 l40 0 0 -40 0 -40 40 0 40 0 0 -80 0 -79
156 0 c86 0 158 -2 160 -4 2 -2 4 -27 5 -55 2 -54 8 -62 29 -37 7 8 21 15 30
15 10 0 20 4 22 9 2 4 48 46 103 92 101 84 251 231 296 289 13 17 36 44 52 60
15 16 27 32 27 36 0 4 9 17 20 29 11 11 47 64 80 115 32 52 65 101 71 108 6 7
26 47 44 88 18 41 37 80 44 87 6 6 11 18 11 27 0 8 8 27 17 40 l17 24 7 -24
c5 -17 7 -9 7 27 -1 31 5 59 13 69 8 9 19 28 24 41 7 17 9 -46 7 -207 -2 -152
1 -243 8 -265 9 -26 8 -39 -1 -57 -11 -20 -14 -21 -17 -6 -2 10 -8 18 -14 18
-6 0 -8 -9 -5 -20 3 -11 1 -20 -4 -20 -5 0 -9 -5 -9 -12 0 -9 3 -9 12 0 37 37
16 -31 -29 -95 -13 -17 -23 -36 -23 -41 0 -6 -3 -12 -7 -14 -5 -1 -21 -23 -38
-48 -16 -25 -41 -59 -55 -76 -14 -18 -36 -46 -48 -63 -60 -78 -213 -238 -317
-332 -42 -37 -167 -132 -245 -184 -127 -87 -408 -230 -490 -250 -14 -4 -38
-13 -55 -20 -16 -8 -55 -23 -85 -35 -51 -19 -53 -20 -16 -16 21 3 47 1 58 -5
13 -7 18 -7 16 3 -3 15 41 18 92 7 20 -5 52 -4 75 3 72 20 338 154 450 228 95
63 209 144 225 160 3 3 29 25 58 49 69 57 219 203 273 267 77 89 86 100 158
196 39 52 71 98 71 102 0 4 13 24 29 44 16 20 49 75 72 122 24 47 46 87 49 90
10 10 90 184 90 195 0 4 7 13 15 22 8 8 13 19 10 24 -4 5 2 6 12 2 15 -6 16
-4 6 8 -15 18 -2 73 23 104 15 19 15 19 11 -7 -2 -16 0 -28 4 -28 5 0 8 -119
8 -265 -1 -238 1 -268 16 -285 16 -18 16 -21 -2 -52 -10 -18 -21 -35 -24 -38
-3 -3 -17 -27 -31 -55 -29 -59 -51 -98 -58 -105 -7 -6 -81 -115 -95 -140 -10
-16 -94 -117 -122 -146 -7 -7 -13 -16 -13 -21 -1 -17 -288 -292 -405 -386 -57
-46 -240 -175 -273 -192 -20 -11 -53 -29 -72 -41 -19 -11 -75 -42 -123 -67
-49 -26 -100 -56 -115 -67 l-27 -21 55 6 c300 29 2067 -1 2078 -35 2 -6 69
-10 163 -10 l159 0 0 -30 c0 -28 2 -30 40 -30 36 0 40 -2 40 -25 0 -22 4 -25
40 -25 29 0 40 4 40 15 0 11 11 15 40 15 33 0 40 -3 40 -20 0 -17 7 -20 40
-20 33 0 40 3 40 20 0 17 7 20 40 20 l40 0 0 -79 0 -80 -37 -3 c-21 -2 15 -2
80 1 l117 4 0 -41 0 -42 80 0 80 0 0 -40 0 -40 40 0 c22 0 40 5 40 10 0 6 18
10 40 10 l40 0 0 -50 0 -50 -80 0 c-73 0 -80 -2 -80 -20 0 -17 -7 -20 -40 -20
-34 0 -40 -3 -40 -21 0 -20 -3 -21 -80 -14 l-80 7 0 -46 0 -46 -40 0 -40 0 0
-40 0 -40 -40 0 c-22 0 -40 -4 -40 -10 0 -5 -18 -10 -40 -10 -38 0 -40 -2 -40
-30 0 -28 -2 -30 -40 -30 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -38 0 -40 -2
-40 -30 l0 -30 -120 0 c-73 0 -120 -4 -120 -10 0 -6 -47 -10 -120 -10 -94 0
-120 -3 -120 -13 0 -16 -233 -27 -582 -27 -135 0 -218 -4 -218 -10 0 -6 -253
-11 -692 -14 -874 -5 -1548 -26 -1548 -48 0 -4 -90 -8 -200 -8 -110 0 -200 -4
-200 -8 0 -11 -549 -32 -1049 -41 -283 -5 -386 -10 -389 -19 -5 -16 -1122 -17
-1122 -2 0 6 -91 10 -252 10 -139 0 -410 5 -603 10 -192 6 -421 11 -507 12
-87 1 -158 6 -158 10 0 4 -72 8 -160 8 -88 0 -160 4 -160 8 0 5 -89 12 -197
16 -108 3 -200 10 -203 15 -3 5 -76 12 -162 16 l-158 7 0 -821 0 -821 5355 0
5355 0 0 5400 0 5400 -5355 0 -5355 0 0 -4103z m5685 1588 c322 -44 605 -130
900 -275 174 -86 208 -105 335 -188 209 -136 419 -319 607 -532 135 -154 283
-372 288 -427 4 -40 13 -53 24 -35 5 8 17 -6 34 -40 37 -72 33 -86 -4 -18 -30
55 -33 49 -13 -30 4 -17 3 -21 -4 -11 -6 9 -8 -1 -5 -30 7 -84 2 -527 -7 -555
-8 -24 -8 -20 -5 21 2 28 2 42 -1 33 -9 -30 -23 -20 -35 25 -24 93 -181 407
-270 542 -40 61 -75 112 -79 115 -3 3 -17 21 -30 40 -59 86 -300 343 -405 431
-137 115 -279 210 -460 308 -297 162 -622 263 -970 301 -129 14 -464 14 -586
0 -432 -50 -866 -214 -1219 -459 -175 -123 -438 -367 -550 -511 -19 -25 -38
-47 -41 -50 -14 -12 -139 -200 -139 -210 0 -5 -3 -10 -8 -10 -4 0 -16 -17 -27
-37 -11 -21 -22 -40 -25 -43 -4 -3 -12 -21 -20 -40 -8 -19 -17 -37 -21 -40
-11 -8 -69 -131 -69 -146 0 -8 -4 -14 -10 -14 -5 0 -10 -7 -10 -15 0 -19 -32
-85 -41 -85 -14 0 -17 30 -18 200 -1 198 -9 292 -25 302 -19 13 2 20 24 8 20
-10 21 -9 18 12 -2 13 -8 22 -15 21 -6 -1 -13 9 -15 22 -3 13 -1 22 3 19 5 -3
9 6 9 20 0 14 5 26 10 26 6 0 13 8 17 18 21 56 196 288 323 425 464 503 1148
851 1805 916 61 6 124 13 140 15 68 10 477 -4 590 -19z m-155 -406 c503 -45
977 -241 1371 -566 83 -68 290 -269 306 -297 5 -8 23 -31 41 -52 46 -55 132
-173 132 -182 0 -4 14 -22 30 -40 17 -18 30 -36 30 -40 0 -4 14 -33 30 -64 17
-32 26 -59 20 -63 -5 -3 -18 13 -28 36 l-20 42 2 -46 c1 -37 5 -47 19 -47 12
0 17 -8 17 -27 0 -26 0 -311 -1 -445 0 -32 7 -70 15 -87 17 -32 22 -61 8 -45
-5 5 -17 35 -26 67 -10 31 -21 57 -25 57 -4 0 -12 18 -17 40 -6 22 -7 37 -3
34 5 -3 9 2 9 11 0 9 -4 14 -8 11 -5 -3 -38 52 -73 122 -70 140 -137 255 -150
260 -5 2 -9 8 -9 13 0 10 -43 73 -119 175 -62 83 -292 309 -381 376 -356 265
-695 405 -1110 459 -345 45 -712 7 -1040 -106 -234 -81 -519 -242 -715 -405
-133 -110 -397 -411 -453 -517 -7 -12 -18 -28 -24 -35 -7 -8 -20 -27 -29 -43
-46 -88 -69 -135 -89 -177 -12 -27 -26 -48 -31 -48 -6 0 -8 -4 -4 -10 3 -5 -4
-19 -15 -30 -12 -12 -19 -28 -15 -39 6 -21 -32 -104 -45 -96 -6 4 -10 12 -10
18 0 7 4 6 10 -3 8 -12 10 -11 10 7 0 12 4 25 10 28 6 4 9 98 8 254 -1 136 2
253 7 260 4 8 2 17 -6 22 -11 8 -10 12 6 25 11 8 19 22 17 30 -2 9 6 18 17 21
12 3 19 9 16 13 -10 16 76 144 169 250 280 320 557 523 959 700 337 148 787
216 1187 179z m-1205 -809 c125 -28 182 -43 198 -51 14 -8 17 -24 17 -94 0
-53 -4 -85 -10 -85 -6 0 -25 7 -42 16 -50 26 -166 63 -219 70 -70 9 -134 -19
-153 -67 -14 -34 -12 -119 3 -119 3 0 26 -18 51 -40 25 -22 50 -40 55 -40 6 0
21 -10 35 -21 14 -11 56 -40 93 -65 91 -61 164 -135 188 -192 17 -39 20 -64
17 -142 -3 -81 -7 -104 -32 -155 -73 -148 -226 -225 -413 -208 -62 6 -76 14
-49 30 11 6 13 19 9 51 -5 39 -8 42 -37 43 -30 2 -30 2 -3 6 15 2 25 8 22 13
-2 4 20 11 50 15 97 12 149 65 142 143 -5 49 -20 69 -96 125 -29 22 -56 48
-59 59 -2 11 -8 15 -11 10 -4 -7 -16 -3 -31 10 -24 19 -25 20 -2 15 23 -5 32
16 12 28 -5 3 -10 11 -10 16 0 6 5 7 10 4 10 -6 12 41 10 395 -1 132 -4 186
-13 192 -7 5 2 8 23 9 l35 2 -32 4 c-18 2 -33 8 -33 13 0 5 15 11 33 14 17 3
43 7 57 10 33 5 135 -3 185 -14z m958 -20 c7 -6 66 -230 133 -505 13 -55 55
-220 92 -367 56 -216 66 -268 55 -273 -22 -8 -321 -6 -326 3 -3 4 -22 88 -42
187 -20 99 -39 183 -41 187 -3 5 -54 8 -114 8 l-109 0 -15 -52 c-9 -29 -29
-114 -47 -188 -17 -74 -33 -141 -35 -147 -2 -10 -37 -13 -129 -13 -69 0 -125
3 -125 7 0 4 13 46 29 93 16 47 63 193 104 325 181 578 230 731 239 742 10 12
316 7 331 -7z m785 -22 c5 -7 22 -65 36 -128 14 -63 41 -184 61 -269 19 -85
35 -158 35 -163 0 -4 4 -8 8 -8 4 0 20 46 35 103 16 56 43 154 62 217 18 63
41 143 51 178 l17 63 136 -2 c75 -1 152 -4 172 -6 l37 -5 -4 -56 c-3 -32 -11
-270 -19 -531 -8 -261 -17 -477 -20 -480 -7 -6 -29 -8 -145 -12 l-95 -3 -3 84
c-2 47 1 200 7 340 10 252 4 318 -19 229 -13 -50 -105 -381 -124 -449 -8 -25
-24 -79 -36 -121 l-23 -76 -60 -7 c-33 -3 -79 -6 -102 -6 l-43 0 -46 193 c-25
105 -59 254 -76 329 -17 76 -34 147 -39 159 -11 31 -21 -105 -21 -291 0 -80
-3 -202 -7 -272 l-6 -127 -81 -3 c-45 -2 -85 0 -90 4 -8 6 11 1086 20 1116 2
9 49 12 187 12 136 0 187 -3 195 -12z m-3306 -200 c-7 -7 -12 -8 -12 -2 0 14
12 26 19 19 2 -3 -1 -11 -7 -17z m355 -240 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m0 -100 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m4423 2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m38 -85 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-4771
-137 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-57 -50 c0 -19 -2
-20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10 10 -22z m27 -20 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-23 -73 c3 -8 1 -15 -4 -15 -6 0 -10 7
-10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-4 -55 c0 -19 -18 -19 -23 -1 -3 14
-1 18 9 14 8 -3 14 -9 14 -13z m5133 -60 c3 -11 1 -23 -4 -26 -5 -3 -9 6 -9
20 0 31 6 34 13 6z m-4759 -15 c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12
19 11 13 -6z m4793 -17 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-7 -49 c0 -11 -4 -17 -10 -14 -5 3 -10 15 -10 26 0 11 5 17 10 14 6 -3 10
-15 10 -26z m-403 -11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-2463 -225 c0 -84 -4 -156 -8 -160 -7 -7 -56 15 -147 68 -120 69 -137 78
-142 66 -2 -7 -5 -93 -6 -192 -2 -274 -13 -799 -16 -805 -4 -7 -295 -2 -313 5
-9 3 -10 10 -3 23 5 10 7 23 3 29 -4 6 -4 94 -1 195 11 309 14 766 6 775 -5 4
-11 2 -15 -4 -4 -7 -28 -16 -52 -22 -37 -8 -42 -7 -28 3 13 9 18 31 20 87 2
54 7 76 18 81 8 3 166 5 350 5 l335 -2 -1 -152z m634 131 l172 -6 0 -67 c0
-36 -4 -72 -9 -80 -7 -11 -44 -13 -205 -9 l-196 5 -1 -143 c-1 -79 -2 -150 -3
-156 -1 -10 40 -14 159 -18 l160 -5 -1 -80 -2 -80 -158 -5 -159 -5 -3 -157 -3
-158 206 0 205 0 0 -84 0 -84 -122 -7 c-68 -3 -233 -7 -368 -8 l-245 -2 2 100
c2 55 7 312 12 572 5 260 12 476 14 480 5 9 264 7 545 -3z m2239 -6 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1788 -8 l103 0 -6 -302 c-3 -167
-9 -376 -12 -465 -5 -138 -4 -163 8 -163 16 0 108 36 198 77 30 14 62 26 70
28 8 2 27 6 43 10 21 6 27 4 27 -9 0 -9 -8 -17 -17 -17 -17 -1 -17 -2 0 -6 15
-4 17 -18 17 -104 0 -96 -1 -99 -22 -100 l-23 -2 23 -4 c28 -5 29 -23 1 -23
-11 0 -17 -4 -14 -10 3 -5 3 -10 -2 -10 -4 0 -76 -5 -159 -10 -82 -5 -220 -10
-305 -11 l-154 -1 2 64 c1 34 7 212 12 393 6 182 12 402 14 490 1 88 6 166 11
174 6 9 20 11 45 7 20 -3 83 -6 140 -6z m1818 -162 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m-4857 -838 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m4426 -87 c-11 -11 -19 6 -11 24 8 17 8 17 12
0 3 -10 2 -21 -1 -24z m394 6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12
10 9 6 -3 10 -10 10 -16z m-5183 -81 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m5163 7 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2
0 4 -7 4 -15z m-13 -80 c0 -8 -7 -15 -15 -15 -12 0 -12 3 -2 15 7 8 13 15 15
15 1 0 2 -7 2 -15z m-5097 -86 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12
10 9 6 -3 10 -10 10 -16z m27 -81 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m2958 -18 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z
m138 3 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m187 -2 c0 -6
-4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-715 -11
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m70 0
c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-430 -10 c-3 -5
-12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m465 0 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m173 3 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m1195 -35 c6 -6 12 -26 12 -44 l0 -34 -63
0 c-56 0 -63 -2 -64 -20 -2 -17 4 -20 41 -20 51 0 66 -13 66 -57 0 -32 -1 -33
-44 -33 -54 0 -83 -9 -65 -21 10 -6 10 -11 0 -23 -12 -14 -5 -16 63 -16 l76 0
0 -32 c0 -54 -6 -57 -125 -58 -61 -1 -114 -1 -120 -1 -8 1 -12 278 -6 366 1
13 212 7 229 -7z m252 -4 c6 -7 10 -25 8 -41 -3 -26 -7 -28 -48 -31 -73 -4
-79 -10 -45 -38 17 -13 35 -24 42 -24 6 0 23 -14 37 -31 20 -23 26 -42 26 -78
0 -80 -41 -111 -145 -111 -76 0 -95 9 -95 46 0 45 8 51 64 46 63 -5 79 9 39
34 -79 48 -94 62 -104 91 -5 17 -7 47 -4 67 10 51 42 75 111 84 68 9 99 5 114
-14z m-3320 -5 c21 -12 43 -32 49 -46 15 -32 14 -205 -1 -238 -20 -45 -79 -65
-185 -65 l-93 0 0 185 0 185 96 0 c80 0 103 -4 134 -21z m315 -4 c50 -29 55
-42 55 -155 0 -74 3 -87 20 -98 16 -10 19 -19 15 -44 -8 -46 -11 -48 -69 -48
-62 0 -76 11 -76 62 0 35 -2 38 -29 38 -28 0 -30 -2 -33 -47 l-3 -48 -46 -5
c-26 -3 -52 -4 -58 -2 -14 5 -15 276 -1 302 6 11 27 31 48 45 50 34 119 34
177 0z m343 13 c7 -7 12 -27 12 -45 0 -32 -1 -33 -40 -33 l-40 0 -2 -97 c-1
-54 -1 -109 -1 -123 3 -60 -2 -66 -59 -65 l-53 1 -3 142 -3 141 -37 3 c-34 3
-37 6 -43 40 -3 21 -4 40 -1 43 12 11 258 5 270 -7z m274 -26 c33 -32 33 -32
33 -135 0 -86 3 -104 15 -103 11 1 15 -10 14 -42 0 -23 -3 -45 -7 -48 -11 -11
-107 -6 -122 6 -9 7 -15 29 -15 51 0 37 -2 39 -31 39 -30 0 -30 -1 -29 -42 1
-24 1 -47 1 -53 -1 -11 -92 -12 -110 -1 -15 9 -15 228 0 281 10 37 30 59 69
77 8 4 45 6 83 5 61 -2 70 -6 99 -35z m1827 4 c62 -59 46 -145 -27 -147 l-37
-1 44 -9 c24 -5 46 -10 48 -13 13 -13 10 -83 -4 -107 -34 -57 -138 -78 -215
-43 -54 24 -72 76 -66 194 4 84 6 92 34 118 39 39 49 42 126 39 58 -2 71 -6
97 -31z m988 -18 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m53
-12 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-1643 -38 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1613 -38 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-963 -52
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m940 0 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2532 -48 c3 -5 1 -10 -4 -10 -6 0 -11
5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m25 -20 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1053 -15 c-3 -9 -8 -14 -10 -11
-3 3 -2 9 2 15 9 16 15 13 8 -4z m-1686 -57 c-2 -13 -4 -3 -4 22 0 25 2 35 4
23 2 -13 2 -33 0 -45z m1668 2 c-3 -11 -2 -20 4 -20 5 0 11 8 14 18 2 9 8 -2
12 -26 4 -24 14 -50 22 -58 19 -19 3 -18 -26 2 -16 12 -20 19 -12 27 17 17 13
27 -9 27 -20 0 -28 28 -13 43 12 12 14 7 8 -13z m69 -59 c-6 -7 -11 2 -17 34
l-6 30 13 -30 c8 -16 12 -32 10 -34z m206 25 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-284 -33 c-10 -10 -19 5 -10 18 6
11 8 11 12 0 2 -7 1 -15 -2 -18z m244 12 c0 -9 -9 -15 -22 -15 -21 0 -21 0 -2
15 24 18 24 18 24 0z m1610 1 c0 -7 -8 -20 -17 -27 -15 -12 -16 -12 -10 4 4
10 7 23 7 28 0 5 5 9 10 9 6 0 10 -6 10 -14z m-1415 -6 c-3 -5 -12 -10 -18
-10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-1500 -10 c-3 -5 -10 -10 -16 -10 -5 0
-9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m75 0 c-20 -13 -40 -13 -40 0 0 6
12 10 28 10 21 0 24 -2 12 -10z m940 0 c0 -5 -20 -10 -45 -10 -25 0 -45 5 -45
10 0 6 20 10 45 10 25 0 45 -4 45 -10z m223 3 c-7 -2 -19 -2 -25 0 -7 3 -2 5
12 5 14 0 19 -2 13 -5z m77 -23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m207 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-3737 -56 c0 -7 -5 -20 -10 -28 -8 -12 -10 -9 -10 13 0 15 5 27 10
27 6 0 10 -6 10 -12z m3889 -300 c-9 -16 -14 -19 -17 -9 -2 7 2 17 9 22 20 13
21 11 8 -13z m-2529 -558 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15
10 8 0 15 -4 15 -10z m-3330 -180 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0
6 18 10 40 10 22 0 40 -4 40 -10z m560 0 c0 -6 -60 -10 -160 -10 -100 0 -160
4 -160 10 0 6 60 10 160 10 100 0 160 -4 160 -10z"/>
<path d="M2825 6910 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M2830 6385 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M2817 6354 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M3207 6299 c-4 -16 -3 -18 9 -8 16 13 19 29 5 29 -5 0 -11 -9 -14
-21z"/>
<path d="M7390 6309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7431 6284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4081 6890 c0 -33 1 -34 10 -11 6 16 6 28 -1 35 -7 7 -10 -1 -9 -24z"/>
<path d="M4081 6774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4088 6003 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5046 6739 c-11 -39 -66 -270 -66 -279 0 -12 109 -13 129 -1 7 5 11
13 8 18 -3 5 -17 67 -31 138 -26 130 -32 149 -40 124z"/>
<path d="M3662 4168 c3 -83 5 -93 22 -96 31 -6 46 26 46 98 0 71 -10 90 -47
90 -23 0 -24 -1 -21 -92z"/>
<path d="M3987 4244 c-4 -4 -7 -24 -7 -43 0 -33 2 -36 31 -37 29 -2 30 -1 27
40 -2 32 -7 42 -23 44 -12 2 -24 0 -28 -4z"/>
<path d="M4577 4244 c-4 -4 -7 -22 -7 -39 0 -29 3 -32 31 -33 29 -1 30 1 27
36 -2 28 -8 38 -23 40 -12 2 -24 0 -28 -4z"/>
<path d="M6407 4243 c-3 -4 -5 -43 -5 -86 l-1 -78 27 3 c23 3 28 9 33 43 11
63 -2 119 -28 123 -12 2 -23 0 -26 -5z"/>
<path d="M7832 4430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7820 4388 c0 -7 5 -20 10 -28 8 -12 10 -9 10 13 0 15 -4 27 -10 27
-5 0 -10 -6 -10 -12z"/>
<path d="M5755 4320 c-3 -5 1 -20 10 -34 11 -16 15 -41 13 -74 -2 -28 -5 -49
-8 -48 -3 1 -17 -10 -31 -24 -23 -22 -25 -28 -14 -44 12 -15 27 -22 58 -25 8
-1 9 -37 1 -68 -5 -17 -17 -24 -58 -31 l-51 -10 57 -1 c48 -1 58 2 63 19 3 11
12 20 20 20 7 0 16 6 18 13 4 9 8 8 16 -3 18 -27 19 -6 2 64 -9 37 -24 74 -34
81 -9 7 -17 16 -17 20 0 4 7 2 15 -5 8 -7 15 -10 15 -8 0 13 -43 134 -51 144
-5 6 -6 14 -2 17 3 4 1 7 -5 7 -6 0 -14 -4 -17 -10z m42 -112 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m23 -177 c0 -6 -4 -13 -10 -16 -5 -3 -10
1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z"/>
<path d="M5600 4245 c0 -8 5 -15 11 -15 5 0 7 5 3 11 -4 7 -1 10 6 7 20 -6 24
-28 6 -28 -8 0 -18 -4 -21 -10 -10 -16 22 -11 40 6 12 12 13 19 4 30 -15 19
-49 18 -49 -1z"/>
<path d="M5330 4100 c0 -23 3 -25 55 -27 l54 -3 -11 25 c-13 28 -10 27 -60 29
-34 2 -38 0 -38 -24z m30 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M5601 4062 c-1 -13 4 -32 9 -43 7 -12 7 -19 1 -19 -6 0 -7 -7 -4 -17
4 -11 3 -14 -5 -9 -7 4 -12 3 -12 -1 0 -16 66 -42 124 -49 43 -5 57 -4 52 5
-4 6 -19 11 -34 11 -15 0 -44 3 -64 6 -30 5 -38 11 -38 27 0 20 0 20 14 1 29
-39 27 -4 -2 38 -16 24 -32 50 -35 58 -3 10 -6 7 -6 -8z"/>
<path d="M5200 3987 c0 -11 9 -21 20 -24 12 -3 20 -14 20 -26 0 -25 13 -14 15
13 1 13 -5 20 -16 20 -10 0 -23 8 -28 18 -10 16 -10 16 -11 -1z"/>
<path d="M5810 3980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5480 3963 c0 -6 9 -9 20 -6 14 4 22 -1 29 -18 l10 -24 0 23 c1 15 6
22 14 19 6 -2 11 -10 10 -16 -2 -6 1 -11 6 -11 6 0 9 9 8 19 -1 16 -10 20 -49
23 -32 2 -48 -1 -48 -9z"/>
<path d="M5803 3953 c-7 -3 -13 -9 -13 -15 0 -6 6 -8 14 -5 7 3 19 0 25 -6 8
-8 11 -8 11 0 0 15 -23 31 -37 26z"/>
<path d="M5270 3938 c0 -5 26 -7 58 -7 61 1 55 6 -15 11 -24 2 -43 0 -43 -4z"/>
<path d="M5445 3940 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5500 3935 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M5590 3941 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M6400 3576 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M6476 3561 c-3 -5 1 -14 8 -20 10 -8 16 -9 21 -1 3 5 1 10 -5 10 -6
0 -8 5 -5 10 3 6 2 10 -4 10 -5 0 -12 -4 -15 -9z"/>
<path d="M6520 3555 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M6391 3483 c-1 -32 -5 -43 -17 -43 -8 0 -12 -5 -9 -10 5 -8 11 -8 19
-1 7 6 17 7 21 3 5 -4 5 -1 1 6 -4 6 -3 12 2 12 5 0 12 11 14 24 3 17 1 23 -8
19 -7 -2 -14 4 -17 14 -3 10 -5 -1 -6 -24z"/>
<path d="M6480 3511 c0 -6 17 -7 38 -4 51 8 53 13 4 13 -23 0 -42 -4 -42 -9z"/>
<path d="M4040 3491 c0 -5 7 -12 16 -15 24 -9 27 -7 10 9 -17 17 -26 19 -26 6z"/>
<path d="M6494 3465 c-10 -14 -26 -25 -34 -25 -9 0 -27 -8 -40 -17 -14 -10
-30 -18 -36 -18 -7 0 -25 -11 -41 -25 -15 -14 -45 -34 -66 -45 -20 -11 -37
-23 -37 -26 0 -9 128 59 135 72 4 5 13 9 21 9 14 0 87 44 112 67 7 7 20 13 28
13 8 0 12 5 9 10 -11 17 -31 11 -51 -15z"/>
<path d="M6148 2863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5848 2773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4788 2763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5650 2750 c-11 -7 -7 -10 17 -10 17 0 35 5 38 10 8 13 -35 13 -55 0z"/>
<path d="M4923 2743 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5383 2713 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M6450 2590 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M4476 2466 c3 -9 13 -16 22 -16 8 0 13 1 11 3 -2 1 -12 9 -22 16 -15
12 -17 12 -11 -3z"/>
<path d="M4540 2440 c36 -11 52 -11 45 0 -3 6 -21 10 -38 9 -31 -1 -31 -1 -7
-9z"/>
<path d="M4698 2413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8898 1763 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
